import * as React from 'react';
import Shell from '../layout/shell';
import './about.scss';

import header from '../assets/about-header.png';
import map from '../assets/about-map.png';
import categories from '../assets/about-categories.png';
import rocketmakers from '../assets/about-rocketmakers.png';
import tech4good from '../assets/about-tech4good-sw.png';
import data from '../assets/about-data.png';
import Footer from '../components/footer';

interface Props {
  heading: string;
}

const About: React.FC<Props> = (props) => {
  return (
    <Shell>
      <div className="about-page">
        <div className="header-stripe">About</div>
        <div className="content">
          <img src={header} />
          <h2>What is Tech for Good?</h2>
          <p>
            There are many definitions of Tech for Good. For this project, we identify ‘Tech for Good’ as a community of
            people, projects, and organisations utilising technology to improve social, environmental and economic
            outcomes and doing so in a way that is collaborative, user-focused and with an end result that is
            collaborative and user-led.
          </p>
          <img src={map} />
          <h2>What is Bristol and Bath Tech for Good?</h2>
          <p>
            The community of companies focusing on Tech for Good in Bristol and Bath is gaining traction and is just
            about keeping pace with rapidly expanding interest in this sector. With more and more people asking who's
            who, at Rocketmakers we were prompted to undertake an action research project to help identify and celebrate
            all the companies working to use tech to have a positive impact on people and planet.  <br /> <br />
            We reached out to our networks and undertook some of our own explorations to put together an open dataset of
            as many of the actors in the local Tech for Good scene that we could find. The accompanying microsite is a
            place for us all to keep a record of this fantastic community - and find our next source for Christmas
            presents and service providers.
          </p>
          <img src={categories} />
          <h2>Our Categorisation</h2>
          <p>
            With over 200 data points covering a great depth and breadth of sub-sectors, we determined by the focus of
            the company and the first map prioritises product and services based businesses specifically using
            technology to deliver positive social, environmental, and economic impact. The second map gives an
            indication of the support community that exists to enable those working towards a positive impact in the
            world.
            <br /> <br />
            There are also projects happening elsewhere in the country to define a clearer lexicon and data structure
            for Tech for Good so we have made this data openly available under license so that it can form a foundation
            or contribution to these other works.
          </p>
          <img src={tech4good} />
          <h2>Tech4Good Southwest</h2>
          <p>
            This project is primarily focused on data collection and celebration of the companies. If you would like to
            join an active, local Tech for Good community, there's only one place to go - join Tech4Good South West.
            Tech4Good South West is a fabulous meetup group, online hub, and network that brings the community together
            to learn, share and support one another. 
          </p>
          <a href="http://techforgoodsw.org.uk/" className="btn green" target="_blank">
            Find out more about Tech4Good Southwest
          </a>
          <img src={rocketmakers} />
          <h2>Rocketmakers: Building Tech for Good Projects</h2>
          <p>
            There are many definitions of Tech for Good. For this project, we identify ‘Tech for Good’ as a community of
            people, projects, and organisations utilising technology to improve social, environmental and economic
            outcomes and doing so in a way that is collaborative, user-led and with an end result that’s ethically
            right-on.
          </p>
          <a href="https://www.rocketmakers.com" className="btn" target="_blank">
            Get in touch with Rocketmakers
          </a>
          <img id="data" src={data} />
          <h2>Tech for Good dataset</h2>
          <p>
            The Tech for Good Bristol and Bath dataset. The full dataset has been shared under a Creative Commons
            license - Attribution-ShareAlike 4.0 International (CC BY-SA 4.0). You can access it on the{' '}
            <a href="/data">Data page</a> in the top left corner and it will be updated on a monthly basis with any
            additional companies. This means that you can use the data as long as you attribute the original work to
            Rocketmakers and share any dataset you produce with it under the same license.
          </p>
        </div>
      </div>
      <Footer />
    </Shell>
  );
};

export default About;
